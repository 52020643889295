var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '30px'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "padding": "20px 90px",
      "paths": [{
        label: 'Beranda',
        href: {
          name: 'nutrisionist.managementClient'
        }
      }, {
        label: 'Manajemen Klien',
        href: {
          name: 'nutrisionist.managementClient'
        }
      }, {
        label: 'Detail Klien',
        href: {
          name: 'nutri.detail-client',
          query: _vm.$route.query
        }
      }, {
        label: 'Detail Program',
        isCurrent: true
      }]
    }
  })], 1), _c('c-box', {
    attrs: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "30px",
      "padding": "0 40px"
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" Detail Program ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" " + _vm._s(_vm.program.name) + " ")])], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" Data Pribadi ")]), _c('BaseDivider', {
    attrs: {
      "margin": "10px 0 30px 0"
    }
  }), _c('c-box', {
    attrs: {
      "display": "flex",
      "gap": "30px"
    }
  }, [_c('c-box', [_c('c-image', {
    attrs: {
      "border-radius": "100% ",
      "object-fit": "cover",
      "size": "150px",
      "src": _vm.getPhotoUser(_vm.user.photoUrl),
      "alt": _vm.user.fullName
    }
  })], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "flex": "1"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Nama ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "18px"
    }
  }, [_vm._v(" " + _vm._s(_vm.user.fullName) + " ")]), _c('BaseText', {
    attrs: {
      "margin-top": "24px",
      "size-mobile": "14px-2",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Program ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "18px"
    }
  }, [_vm._v(" " + _vm._s(_vm.program.name) + " (" + _vm._s(_vm.program.programService) + ") ")]), _c('BaseText', {
    attrs: {
      "margin-top": "24px",
      "size-mobile": "14px-2",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Join Date - End Date ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "18px"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDateV2(_vm.program.startAt)) + " - " + _vm._s(_vm.formatDateV2(_vm.program.endAt)) + " ")]), _c('BaseText', {
    attrs: {
      "margin-top": "24px",
      "size-mobile": "14px-2",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Durasi ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "18px"
    }
  }, [_vm._v(" " + _vm._s(_vm.program.duration) + " Hari ")])], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px",
      "color": "#008C81"
    }
  }, [_vm._v(" Counter day: " + _vm._s(_vm.program.counterDay) + " ")])], 1)], 1)], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" Ahli Gizi yang Dipilih ")]), _c('BaseDivider', {
    attrs: {
      "margin": "10px 0 30px 0"
    }
  }), _c('CardNutritionist', {
    attrs: {
      "name": _vm.getFullnameAndTitle(_vm.nutritionist.fullName, _vm.nutritionist.education),
      "photo": _vm.nutritionist.photoUrl,
      "service-hours": _vm.nutritionist.serviceHour,
      "ratings": _vm.nutritionist.rating,
      "educations": _vm.educationsParser(_vm.nutritionist.education),
      "specializations": _vm.nutritionist.specialization
    },
    on: {
      "on-show-profile": function onShowProfile($event) {
        _vm.isModalOpen = true;
      }
    }
  })], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" Diet Data ")]), _c('BaseDivider', {
    attrs: {
      "margin": "10px 0 30px 0"
    }
  }), _c('c-box', {
    attrs: {
      "display": "grid",
      "grid-template-columns": "repeat(2, minmax(0, 1fr))",
      "grid-gap": "30px"
    }
  }, _vm._l(_vm.LIST_MENU_DIET_DATA, function (menu) {
    return _c('SimpleCard', {
      key: menu.label,
      attrs: {
        "icon": menu.icon,
        "label": menu.label,
        "button-label": menu.buttonLabel
      },
      on: {
        "on-button-click": function onButtonClick($event) {
          menu.onButtonClick && _vm.routerPush(menu.onButtonClick);
        },
        "on-click": function onClick($event) {
          return _vm.routerPush(menu.onClick);
        }
      }
    });
  }), 1)], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" Jadwal Panduan Makan ")]), _c('BaseDivider', {
    attrs: {
      "margin": "10px 0 30px 0"
    }
  }), _c('c-box', {
    attrs: {
      "display": "grid",
      "grid-template-columns": "repeat(2, minmax(0, 1fr))",
      "grid-gap": "30px"
    }
  }, _vm._l(_vm.meal_plan_schedule, function (v) {
    return _c('SimpleCard2', {
      key: v.id,
      attrs: {
        "title": v.title,
        "description": v.date,
        "status": v.status
      }
    });
  }), 1)], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" Jadwal ADIME Notes & Indikator Progress and Tracker ")]), _c('BaseDivider', {
    attrs: {
      "margin": "10px 0 30px 0"
    }
  }), _c('c-box', {
    attrs: {
      "display": "grid",
      "grid-template-columns": "repeat(2, minmax(0, 1fr))",
      "grid-gap": "30px"
    }
  }, _vm._l(_vm.adime_notes_and_indicator_progress_and_tracker_schedule, function (v) {
    return _c('SimpleCard2', {
      key: v.id,
      attrs: {
        "title": v.title,
        "description": v.date,
        "status": v.status
      }
    });
  }), 1)], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" Jadwal Follow Up Notes ")]), _c('BaseDivider', {
    attrs: {
      "margin": "10px 0 30px 0"
    }
  }), _c('c-box', {
    attrs: {
      "display": "grid",
      "grid-template-columns": "repeat(2, minmax(0, 1fr))",
      "grid-gap": "30px"
    }
  }, _vm._l(_vm.follow_up_notes_schedule, function (v) {
    return _c('SimpleCard2', {
      key: v.id,
      attrs: {
        "title": v.title,
        "description": v.date,
        "status": v.status
      }
    });
  }), 1)], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" Jadwal Progress & Tracker Feedback ")]), _c('BaseDivider', {
    attrs: {
      "margin": "10px 0 30px 0"
    }
  }), _c('c-box', {
    attrs: {
      "display": "grid",
      "grid-template-columns": "repeat(2, minmax(0, 1fr))",
      "grid-gap": "30px"
    }
  }, _vm._l(_vm.progress_and_tracker_feedback_schedule, function (v) {
    return _c('SimpleCard2', {
      key: v.id,
      attrs: {
        "title": v.title,
        "description": v.date,
        "status": v.status
      }
    });
  }), 1)], 1)], 1), _c('ModalNutritionists', {
    attrs: {
      "is-show-skeleton": _vm.isShowSkeletonModalNutritionist,
      "is-open": _vm.isModalOpen,
      "fullname": _vm.getFullnameAndTitle("".concat(_vm.nutritionistModal.firstName || '', " ").concat(_vm.nutritionistModal.lastName || '').trim(), _vm.nutritionistModal.education),
      "photo-url": _vm.nutritionistModal.photoUrl,
      "str-number": _vm.nutritionistModal.str,
      "languages": _vm.nutritionistModal.languages ? [_vm.nutritionistModal.languages] : [],
      "rating": _vm.nutritionistModal.rating,
      "problem-handles": _vm.nutritionistModal.problemHandled,
      "client-age-handles": _vm.nutritionistModal.clientAgeHandled ? [_vm.nutritionistModal.clientAgeHandled] : [],
      "specializations": _vm.nutritionistModal.specialization,
      "educations": _vm.educationsParser(_vm.nutritionistModal.education),
      "work-experiences": _vm.workExperiencesParser(_vm.nutritionistModal.workExperiences),
      "service-hour": _vm.nutritionistModal.serviceHour,
      "chat-active-hours": _vm.chatActiveHoursParser(_vm.nutritionistModal.activeHour),
      "service-times": _vm.serviceTimesParser(_vm.nutritionistModal.serviceTime)
    },
    on: {
      "on-close": function onClose($event) {
        _vm.isModalOpen = false;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }